import { CustomerBill } from "protobuf/billing_service_pb";
import {BillingState, BillingAction, CustomerBillingItem} from "types/store";

const initialState: BillingState = {
    bill: undefined,
    totalBill: "PKR 0",
};

export default function billingReducer(
    state: BillingState = initialState,
    action: BillingAction
): BillingState {
    switch (action.type) {
        case "SET_BILL":
          return action.payload
            ? { ...state, bill: action.payload }
            : { ...initialState }; // Reset to initial state if payload is null
        case "SET_TOTAL_BILL":
          return {
            ...state,
            totalBill: action.payload || "PKR 0",
          };
        default:
          return state;
      }
}
