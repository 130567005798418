import { Tag } from "components/base";
import { dashboardUserIconIcon, OpenViewIcon } from "svgs";
import { classNames } from "utils/helpers";
import { unassignedVehiclesData, unresolvedissuesData } from "./__mock__";

export function addAssignee(
  value: string,
  row: typeof unassignedVehiclesData[number]
) {
  return (
    <article className="dashboard-table-link show-on-hover">
      {dashboardUserIconIcon} <span>Add Assignee</span>
    </article>
  );
}

export function vehicleAction(
  value: string,
  row: typeof unassignedVehiclesData[number]
) {
  return (
    <article className="open-view-link-container">
      <span> {row.vehicle_no}</span>
      <span className="show-on-hover">{OpenViewIcon}</span>
    </article>
  );
}

export function renderIssueId(
  value: string,
  row: typeof unresolvedissuesData[number]
) {
  return (
    <article className="open-view-link-container">
      <span> {row.issue_id}</span>
      <span className="show-on-hover">{OpenViewIcon}</span>
    </article>
  );
}

export function renderVehicleId(
  value: string,
  row: typeof unresolvedissuesData[number]
) {
  return (
    <article className="open-view-link-container">
      <span> {row.vehicle_id}</span>
      <span className="show-on-hover">{OpenViewIcon}</span>
    </article>
  );
}

export function renderIssueType(
  value: string,
  row: typeof unresolvedissuesData[number]
) {
  const issueTypeClasses = classNames({
    "--purple": row.issue_type === "Accident",
    "--orange": row.issue_type === "Breakdown",
    "--blue": row.issue_type === "Service",
    "--yellow": row.issue_type === "Vehicle missing",
  });
  console.log(row.issue_type);
  return (
    <Tag
      customClasses={`issue-type-tag --fixed-dashboard-tag  ${issueTypeClasses}`}>
      {row.issue_type}
    </Tag>
  );
}
