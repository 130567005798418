import { Row, Col } from "antd";

import { VehicleTile } from "components";
import {FINANCIALS_TIES_DATA, FINANCIALS_WIDGET} from "../__mock__";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../store";
import {useEffect, useMemo} from "react";
import {parseBillingFieldsTable} from "../../../../utils/parsers/billing-parser";
import {useCookies} from "react-cookie";
import { setTotalBill } from "store/actions/billing-actions";


export interface FinancialsTileContainerProps {
  selectedDate?: string;
}

export default function FinancialsTileContainer({ selectedDate }: FinancialsTileContainerProps) {
  const [{ meta }] = useCookies();
  const dispatch = useDispatch();
  const {
    bill,
    totalBill
   } = useSelector<AppState, AppState["billing"]>((state) => state.billing);
  useEffect(() => {
    if (meta?.user?.organizationId && selectedDate) {
      dispatch(({ 
        organization_id: meta.user.organizationId, 
        date: selectedDate 
      }));
      
    }
  }, [dispatch, meta, selectedDate]);


  const {
    organizationId
  } = useSelector<AppState, AppState["common"]>((state) => state.common);
  const data = useMemo(
      () => parseBillingFieldsTable(bill),
      [bill]
  );
  interface BillingTileProps {
    title: string;
    value: string;
  }
  interface BillingSectionProps {
    title: string;
    children: React.ReactNode;
  }
  
  const BillingTile: React.FC<BillingTileProps> = ({ title, value }) => (
    <div className="billing-tile">
      <p>{title}</p>
      <h1>{value}</h1>
    </div>
  );
  
  const BillingSection: React.FC<BillingSectionProps> = ({ title, children }) => (
    <div className="billing-section">
      <h2>{title}</h2>
      {children}
    </div>
  );
  
  interface BillingRowProps {
    children: React.ReactNode;
  }
  
  const BillingRow: React.FC<BillingRowProps> = ({ children }) => (
    <div className="billing-row">
      {children}
    </div>
  );

  return (
     // <Row className="tiles-container">
    //   {Object.values(data).map((tile) => 
    //   (
        
        // <Col key={tile.name} className={tile.className}>
        //   <VehicleTile
        //     name={tile.name}
        //     value={tile.value}
        //     buttonText={tile.buttonText}
        //     isAmount={tile.isAmount}
        //     paymentPer={tile.paymentPer}
        //   />
        // </Col>
      <div className="billing-container">
        
        <BillingSection title="Battery Subscription Cost">
          <BillingRow>
          <BillingTile title="Total Distance" value={data.distance + " KM"} />
            <BillingTile title="Rate Per Km" value={data.perKmCost} />
            <BillingTile title="Total Subscription Cost" value={"PKR " + data.batterySubCharges} />
          </BillingRow>
        </BillingSection>

        <BillingSection title="Battery Swap Cost">
          <BillingRow>
            <BillingTile title="Total Swaps" value={"" + data.swaps} />
            <BillingTile title="Per Swap Cost" value={ data.perSwapCost} />
            <BillingTile title="Total Swaps Cost" value={"PKR " + data.swapCharges} />
          </BillingRow>
        </BillingSection>

{/*         
    <BillingSection title="Service Contract Cost">
      <BillingRow>
        <BillingTile title="Contracts Purchased" value="5" />
        <BillingTile title="Monthly Cost / Contract" value="PKR 5,000" />
        <BillingTile title="Total Service Cost" value="PKR 25,000" />
      </BillingRow>
    </BillingSection>

    <BillingSection title="Home Charger Cost">
      <BillingRow>
        <BillingTile title="Total Home Charger" value="5" />
        <BillingTile title="Rent per Charger" value="PKR 5,000" />
        <BillingTile title="Total Home Chargers Cost" value="PKR 25,000" />
      </BillingRow>
    </BillingSection> */}

           {data.billingItems?.map((dynamicItems, index) => (
              <BillingSection key={index} title={dynamicItems.item}>
                  <BillingRow>
                      <BillingTile title={""+ dynamicItems.units == "KM" ? "Distance" : "Quantity"} value={ dynamicItems.quantity  + " " + dynamicItems.units} />
                      <BillingTile title="Rate" value={dynamicItems.rate != 0?  "PKR " + dynamicItems.rate: "-"} />
                      <BillingTile title="Total" value={"" + dynamicItems.total} />
                  </BillingRow>
              </BillingSection>
          ))}

      </div>
      )
}
