
import { Dispatch } from "react";
import {
    Notificatons,
    FilterItem,
    NotificationsActions,
    NotificationsState,
    CommonActions,
    GetState, NotificationCategory
} from "types/store";
import {
  batteryManagmentServiceClient,
  fcmNotificationServiceClient,
  fcmUserServiceClient
} from "../../utils/grpc-clients";
import { apiResponse } from "./common-actions";
import { Filters } from "protobuf/filter_pb";
import {getNotificationFiltersValues, getValueArrFromFilterOptions} from "utils/helpers";
import { PageRequest, PageResponse } from "protobuf/page_pb";
import {
    GetNotificationsRequest,
    GetNotificationsResponse,
    GetNotificationCategoriesRequest,
    GetNotificationCategoriesResponse,
    NotificationFilter
} from "protobuf/fcm_notification_service_pb";
import {FcmNotificationServiceClient} from "../../protobuf/Fcm_notification_serviceServiceClientPb";
import {NotificationSubCategory} from "../../protobuf/fcm_notification_service_pb";
import {CategoryResponse} from "../../protobuf/traffic-fine-service_pb";
import {string} from "yup";

export function setNotificationsPage(
  payload: PageResponse.AsObject
): NotificationsActions {
  return {
    type: "SET_NOTIFICATION_TOTAL_PAGE",
    payload,
  };
}

export function setNotficationsSearch(
  payload: NotificationsState["search"]
): NotificationsActions {
  return {
    type: "SET_NOTIFICATIONS_SEARCH",
    payload,
  };
}

export function getFormatedDate(inputString:string){
  const inputDate = new Date(inputString);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
};

 return inputDate.toLocaleString('en-US', options);
}

export function capitalizeFirstLetter(inputString:string) {
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

export function initializeNotifications(
    pageNo: number = 1,
    updatedFilters: FilterItem,
    triggerLoading: boolean = true
) {
    return async (dispatch: Dispatch<any>, getState: GetState) => {
        const { search } = getState().notificationCenter;
        const statusFilterList = getValueArrFromFilterOptions("Status", updatedFilters)
        let filters = new NotificationFilter()
        filters = getNotificationFiltersValues(updatedFilters)
        let priorityList = getValueArrFromFilterOptions("Priority", updatedFilters)

        const sort = new PageRequest.Sort();
        sort.setField("created_at");
        sort.setOrder(2);
        const page = new PageRequest();
        page.setPageSize(20);
        page.setPageNumber(pageNo);
        page.setSort(sort);
        const request = new GetNotificationsRequest();
        request.setPage(page)
        request.setFilters(filters)

        if(statusFilterList.length > 0) {
            filters.setUnreadOnly(true)
        }
        let priorityIntList = priorityList.map((p) => {
            return parseInt(p)
        })
        filters.setSearchString(search)
        console.log("priorityIntList : ", priorityIntList)
        filters.setPriorityList(priorityIntList)
       // request.addCategoryId("1233")
        function dispatcher(resp: GetNotificationsResponse) {
          const page = resp?.getMetadata()?.toObject() || {
              totalElements: 0,
              totalPages: 0,
          };
       
            const notifications: Notificatons[] = resp?.getNotificationsList().map((notification) => ({
                id: notification.getId(),
                title: notification.getTitle(),
                body: notification.getBody(),
                categoryId: notification.getCategoryId(),
                categoryName: notification.getCategoryName(),
                isRead: notification.getIsRead(),
                notificationTime:notification.getNotificationTime(),
                readByUser: notification.getReadByUser(),
                subCategoryId: notification.getSubCategoryId(),
                subCategoryName:notification.getSubCategoryName(),
                priority:notification.getPriority(),
            }));

        dispatch(setNotificationsPage(page));
            dispatch(setNotificationsList(notifications));
        }

        dispatch(
            apiResponse<
            FcmNotificationServiceClient,
            GetNotificationsRequest,
            GetNotificationsResponse
            >({
                request,
                dispatcher,
                client: fcmNotificationServiceClient,
                action: "getNotifications",
                triggerLoading,
            })
        );
    

    };
}

export function getCategories() {
  return (dispatch: Dispatch<any>) => {
    const request = new GetNotificationCategoriesRequest();

    function dispatcher(resp: GetNotificationCategoriesResponse) {
      // Extract categories list
      const categories: NotificationsState["categories"] = resp
        .getCategoriesList()
        .map((category) => {
            const cat:NotificationCategory = {
                id:category.getId(),
                name: category.getName(),
                subCategories: category.getSubCategoriesList().map((subCat) => subCat.toObject() as NotificationSubCategory.AsObject)
            }
            return cat
        });

      // Extract subcategories and ensure they conform to the expected structure
      const subCategories: NotificationsState["subCategories"] = resp
        .getCategoriesList()
        .flatMap((category) => {
          const categoryObject = category.toObject();
          // Check if subCategoriesList exists
          if (categoryObject?.subCategoriesList) {
            return categoryObject.subCategoriesList.map((subCat) => subCat as NotificationSubCategory.AsObject);
          }
          return []; // Return an empty array if no subcategories
        });
      // Dispatch categories and subcategories
      dispatch(setCategories(categories));  
      dispatch(setSubCategories(subCategories));
      dispatch(setNotificationDynamicFiltersByCategory(categories))
    }

    // Dispatch API request and pass the dispatcher
    dispatch(
      apiResponse<
        FcmNotificationServiceClient,
        GetNotificationCategoriesRequest,
        GetNotificationCategoriesResponse
      >({
        request,
        client: fcmNotificationServiceClient,
        action: "getNotificationCategories",
        dispatcher,
      })
    );
  };
}


// export function setFilters() {
//   return (dispatch: Dispatch<any>) => {
//     const request = new GetNotificationCategoriesRequest();

//     dispatch(
//       apiResponse<
//         FcmNotificationServiceClient,
//         GetNotificationCategoriesRequest,
//         GetNotificationCategoriesResponse
//       >({
//         request,
//         action: "getNotificationCategories",
//         client: fcmNotificationServiceClient,
//         dispatcher: (resp) => {
//           const list = resp
//             .getCategoriesList()
//             .map((item) => item.toObject());
//             console.log("setFilters :",list);
//           dispatch({ type: "SET_NOTIFICATION_CATEGORY", payload: list})
//         },
//       })
//     );
//   };
// }


export function setSelectedNotifications(
  payload: (Notificatons | null)[]
): { payload: (Notificatons | null)[]; type: string } {
  return {
      type: "SET_SELECTED_NOTIFICATIONS",
      payload,
  };
}

export function setBulkActionsNotifications(
  payload: NotificationsState["showbulkActionsNotifications"]
): NotificationsActions {
  return {
    type: "SET_NOTIFICATIONS_BULK_ACTIONS",
    payload,
  };
}

export function setNotificationsSearch(
    payload: NotificationsState["search"]
): NotificationsActions {
    return {
        type: "SET_NOTIFICATIONS_SEARCH",
        payload,
    };
}

function getFilterState(data: FilterItem, type: "full" | "empty" = "full") {
  if (type === "empty") {
    const emptyObj: any = {};
    Object.keys(data).forEach((filterName) => {
      emptyObj[filterName] = [];
    });
    return emptyObj as FilterItem;
  }

  return data;
}
export function setCategories(payload: NotificationsState["categories"]): NotificationsActions {
  return {
    type: "SET_NOTIFICATION_CATEGORY",
    payload,
  };
}
export function setSubCategories(payload: NotificationsState["subCategories"]): NotificationsActions {
  return {
    type: "SET_NOTIFICATION_SUB_CATEGORY",
    payload,
  };
}
export function setNotificationsSelectedPage(payload: number): NotificationsActions {
    return {
      type: "SET_NOTIFICATION_SELECTED_PAGE",
      payload,
    };
  }

export function setNotificationsList(payload: Notificatons[]): NotificationsActions {
  return {
    type: "SET_NOTIFICATIONS_TABLE",
    payload,
  };
}

export function setNotificationDynamicFilters(subCategories: NotificationsState["subCategories"]) {
  return (dispatch: Dispatch<NotificationsActions>, getState: GetState) => {
    const { original } = getState().notificationCenter.filters;
    const subCategoriesFilters =
    subCategories?.map((subCategory) => ({
        label: subCategory.name,
        value: subCategory.id,
      })) || [];
console.log("setNotificationDynamicFilters subCategoriesFilters :",subCategoriesFilters);
   dispatch(setNotificationOriginalFilters({ ...original, subCategories: subCategoriesFilters }));
  };
}

export function setNotificationDynamicFiltersByCategory(categories: NotificationsState["categories"]) {
    return (dispatch: Dispatch<NotificationsActions>, getState: GetState) => {
        const { original } = getState().notificationCenter.filters;
        const { updated } = getState().notificationCenter.filters;
        console.log("categories in filter action", categories)
        console.log("original in filter action", original)
        console.log("updated in filter action", updated)
        if(categories) {
            categories.map((cat) => {
               const subCats = cat?.subCategories?.map((subCategory) => ({
                   label: subCategory.name,
                   value: subCategory.id,
               })) || [];
                original[cat?.name || ""] = subCats
                updated[cat?.name || ""] = []
                if(!(updated[cat?.name || ""])) {
                    updated[cat?.name || ""] = [];

                }
            });
        }
        dispatch(setNotificationOriginalFilters(original))
        dispatch(updateNotificationsFilters(updated))
    };
}
export function updateNotificationsFilters(
  payload: NotificationsState["filters"]["updated"]
): NotificationsActions {
  return {
    type: "SET_NOTIFICATIONS_UPDATED_FILTERS",
    payload,
  };
}

export function setNotificationOriginalFilters(
  payload: NotificationsState["filters"]["original"]
): NotificationsActions {
  return {
    type: "SET_NOTIFICATIONS_ORIGINAL_FILTERS",
    payload,
  };
}

export function onChangeNotificationsFilters(
  name: string,
  value: string[],
  type: "all" | "list"
) {
  return (
    dispatch: Dispatch<NotificationsActions | CommonActions>,
    getState: GetState
  ) => {
    const {
      notificationCenter: {
        filters: { original, updated },
      },
    } = getState();
    if (type === "list") {
      const filteredValues = original[name].filter((filter) =>
        value.includes(filter.value)
      );
      const updatedFilters = { ...updated, [name]: filteredValues };
      console.log("Updated filter: ",updatedFilters)
      dispatch(updateNotificationsFilters(updatedFilters));
    } else {
      const updatedFilters = {
        ...updated,
        [name]: value ? original[name] : [],
      };
      dispatch(updateNotificationsFilters(updatedFilters));
    }
  };
}

export function onChangeRootNotificationsFilters(checked?: boolean) {
  return (
    dispatch: Dispatch<NotificationsActions | CommonActions>,
    getState: GetState
  ) => {
    const {
      notificationCenter: {
        filters: { original },
      },
    } = getState();
    const updatedFilters = checked
      ? getFilterState(original, "full")
      : getFilterState(original, "empty");
    dispatch(updateNotificationsFilters(updatedFilters));
  };
}

