import Wrapper from "zyp-kiosk";
import {useSelector} from "react-redux";
import {AppState} from "../store";
import {USER_TYPE} from "../utils/mapped-data";


export default function Kiosks() {
    const {
        common: { token },
    } = useSelector<AppState, AppState>((state) => state);
    const { userType, phone } = useSelector<AppState, AppState["common"]>(
        (state) => state.common
      );
    console.log("reached in kiosk prod tab...");
    return (
<Wrapper
  login= {false} 
  token={token} env="prod"  role={
    userType !== undefined 
      ? (USER_TYPE[userType] === "Fleet Owner Admin" ? "CLIENT_ADMIN" : USER_TYPE[userType]?.toUpperCase()) 
      : ''
  }
/>
    );
}